import { useEffect } from "react";
import { useRouter } from "next/router";
import { wmaxxUrl } from "utils/config";

const supportedRoutes = ["orders", "coupons"];

const Index = () => {
  const router = useRouter();
  const { query, asPath } = router;

  // Rerouting for old url format
  const currentPage = query.cmd;
  const params = asPath.match(/\?.*$/)?.[0] || "";

  useEffect(() => {
    if (supportedRoutes.includes(currentPage)) {
      router.replace(`/${currentPage}${params}`);
      return undefined;
    }
    window.location.replace(wmaxxUrl + asPath.slice(1));
  }, [asPath, currentPage, params, router]);

  return null;
};

export default Index;
